<template>
  <base-section
    id="biljetter"
  >
    <base-section-heading title="Aktuella föreställningar">
    </base-section-heading>

    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="7"
        >
<!--                <div class="text-center">
            <base-btn
              :tile="false"
              color="primary"
              href="https://secure.tickster.com/sv/xtwbvg4waj123ld/products"
              rounded
            >
              KÖP Biljetter (Till min kära fiende)

              <v-icon right>
                mdi-ticket-outline
              </v-icon>
            </base-btn>
          </div> -->
          <v-simple-table>
            <thead>
              <tr>
                <th />
                <th />
                <th />
              </tr>
            </thead>

            <tbody class="body-1">
              <tr
                v-for="([name, one, two], i) in features"
                :key="i"
              >
                <td v-text="name" />

                <td class="text-center font-weight-bold">
                  <template v-if="typeof one === 'boolean'">
                    <v-icon :color="one ? 'success' : 'error'">
                      mdi-{{ one ? 'check' : 'close' }}
                    </v-icon>
                  </template>

                  <template v-else>
                    {{ one }}
                  </template>
                </td>

                <td class="text-center font-weight-bold">
                  <template v-if="typeof two === 'boolean'">
                    <v-icon :color="two ? 'success' : 'error'">
                      mdi-{{ two ? 'check' : 'close' }}
                    </v-icon>
                  </template>

                  <template v-else>
                    {{ two }}
                  </template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>

      </v-row>
      <v-row
        align="center"
        justify="center"
      >
    <p>
     För bokningar, kontakta <a href="mailto:info@johker.se" title="Bokning">info@johker.se</a>
    </p>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProChart',

    data: () => ({
      features: [
        ['', '', ''],
        // ['2022-04-02 kl 14.00', 'Till min kära fiende', 'Hällagården, Borensberg'],
      ],
    }),
  }
</script>
